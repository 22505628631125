<template>
  <b-modal
    id="DelegataireModal"
    ref="DelegataireModal"
    hide-footer
    @hide="close"
  >
    <template #modal-header>
      <h5>{{ $t("NEW") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addDelegataire">
      <div class="center">
        <b-form-group :label="$t('NAME') + '*'">
          <b-form-input
            v-model="v$.newDelegataire.nom.$model"
            :placeholder="$t('NAME') + ' ' + $t('DELEGATAIRE')"
            :state="validateState('nom')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="nom-feedback"
            required
          ></b-form-input>
          <error-handle
            :list="v$.newDelegataire.nom.$errors"
            id="nom-feedback"
          ></error-handle>
          <div v-if="erreurlist.nom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.nom)">
              <span v-for="(e, index) in erreurlist.nom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.nom }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('SIREN')">
          <b-form-input
            v-model="v$.newDelegataire.siren.$model"
            :placeholder="$t('SIREN') + ' ' + $t('DELEGATAIRE')"
            :state="validateState('siren')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="siren-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newDelegataire.siren.$errors"
            id="siren-feedback"
          ></error-handle>
          <div v-if="erreurlist.siren" class="error-message">
            <ul v-if="Array.isArray(erreurlist.siren)">
              <span v-for="(e, index) in erreurlist.siren" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.siren }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('PARAGRAPH') + ' ' + $t('MONTANT_CEE')">
          <b-form-textarea
            v-model="v$.newDelegataire.paragraphCEE.$model"
            :state="validateState('paragraphCEE')"
            :placeholder="$t('PARAGRAPH') + ' ' + $t('MONTANT_CEE')"
            maxlength="1000"
            rows="3"
            required
          ></b-form-textarea>
          <error-handle
            :list="v$.newDelegataire.paragraphCEE.$errors"
            id="paragraphCEE-feedback"
          ></error-handle>
          <div v-if="erreurlist.paragraphCEE" class="error-message">
            <ul v-if="Array.isArray(erreurlist.paragraphCEE)">
              <span v-for="(e, index) in erreurlist.paragraphCEE" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.paragraphCEE }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('PARAGRAPH') + ' ' + $t('MA_PRIME_RENOV')">
          <b-form-textarea
            v-model="v$.newDelegataire.paragraphMPR.$model"
            :state="validateState('paragraphMPR')"
            :placeholder="$t('PARAGRAPH') + ' ' + $t('MA_PRIME_RENOV')"
            maxlength="1000"
            rows="3"
            required
          ></b-form-textarea>
          <error-handle
            :list="v$.newDelegataire.paragraphMPR.$errors"
            id="paragraphMPR-feedback"
          ></error-handle>
          <div v-if="erreurlist.paragraphMPR" class="error-message">
            <ul v-if="Array.isArray(erreurlist.paragraphMPR)">
              <span v-for="(e, index) in erreurlist.paragraphMPR" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.paragraphMPR }}</span>
          </div>
        </b-form-group>
        <b-form-group label="Placeholder disponible:">
          <ul>
            <li>%DELEGATAIRE% => {{ $t("NAME") }} {{ $t("DELEGATAIRE") }}</li>
            <li>%SIREN% => {{ $t("SIREN") }} {{ $t("DELEGATAIRE") }}</li>
            <li>%MONTANT% => {{ $t("MONTANT") }}</li>
          </ul>
        </b-form-group>

        <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
          <multiselect
            v-model="newDelegataire.entreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
          <div v-if="erreurlist.entreprise_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.entreprise_id)">
              <span v-for="(e, index) in erreurlist.entreprise_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.entreprise_id }}</span>
          </div>
        </b-form-group>
      </div>
      <div class="double">
        <b-alert variant="warning" show v-if="errorS" class="messageError">
          {{ errorS }}
        </b-alert>
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" type="submit">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getDelegataireLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  required,
  minLength,
  maxLength,
  alphaNum,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: {
    errorHandle,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      newDelegataire: {
        nom: null,
        siren: null,
        paragraphCEE: null,
        paragraphMPR: null,
        entreprise: null,
      },
      erreurlist: {
        nom: null,
        siren: null,
        paragraphCEE: null,
        paragraphMPR: null,
        entreprise_id: null,
      },
      favoris: null,
      errorS: false,
      box: "",
      placeholder: this.$t("MAPRIME"),
    };
  },
  validations() {
    return {
      newDelegataire: {
        nom: { required, maxLength: maxLength(100) },
        siren: { required, alphaNum, minLength: minLength(9) },
        paragraphCEE: { required, maxLength: maxLength(1000) },
        paragraphMPR: { required, maxLength: maxLength(1000) },
      },
    };
  },
  methods: {
    ...mapActions(["all_entreprises", "store_Delegataires"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newDelegataire[name];
      return $dirty ? !$error : null;
    },

    resetModal() {
      this.errorS = null;
      this.newDelegataire = {
        nom: null,
        siren: null,
        paragraphCEE: null,
        paragraphMPR: null,
        entreprise: null,
      };
      this.erreurlist = {
        nom: null,
        siren: null,
        paragraphCEE: null,
        paragraphMPR: null,
        entreprise_id: null,
      };
      this.v$.$reset();
      this.$refs["DelegataireModal"].hide();
    },

    close(bv) {
      if (this.$refs["DelegataireModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async addDelegataire() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.erreurlist = {
        nom: null,
        entreprise_id: null,
      };
      this.errorS = null;
      const data = {
        nom: this.newDelegataire.nom,
        siren: this.newDelegataire.siren,
        paragraphCEE: this.newDelegataire.paragraphCEE,
        paragraphMPR: this.newDelegataire.paragraphMPR,
      };

      data.entreprise_id = this.newDelegataire.entreprise
        ? this.newDelegataire.entreprise.id
        : this.getOnlineUser.entreprise.id;

      await this.store_Delegataires(data)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
  },

  computed: {
    ...mapGetters([
      "getAllentreprises",
      "getDelegataireLoading",
      "getOnlineUser",
    ]),
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
.actionModel {
  position: relative;
  padding: 52px 0 0;

  .messageError {
    position: absolute;
    top: 5px;
    bottom: 44px;
    color: #e4261b;
    margin: 0;
    font-size: 15px;
    background-color: #fff;
    border-color: #fff;
  }
}

ul {
  color: #393939;
}
</style>
